import React, { useCallback, useEffect } from 'react';
import { Navbar } from '../Navbar/Navbar';
import { NotAuthorizedNavbar } from '../Navbar/NotAuthorizedNavbar';
import { Button, Flex, Text, useMediaQueries } from '@gr/ui-neo';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useSession from '../../hooks/useSession';
import useApi from '../../hooks/useApi';

export function Header(): JSX.Element {
    const [isLessThan920px] = useMediaQueries(['(max-width: 920px)']);
    const [shouldShowTaxDetailsBanner, setShouldShowTaxDetailsBanner] = React.useState(false);
    const [shouldShowLinkedAccountBanner, setShouldShowLinkedAccountBanner] = React.useState(false);
    const [shouldShowGetresponseButton, setShouldShowGetresponseButton] = React.useState(false);
    const router = useRouter();
    const { pathname } = router;
    const { session, setPersonalDetailsFilled } = useSession();
    const { api } = useApi();

    const checkIfShouldShowBanner = useCallback(async (): Promise<void> => {
        try {
            const response = await api('/api/users');
            const { user } = await response.json();

            if (user.personal_details_filled === false && session.personalDetailsFilled !== 'no') {
                setPersonalDetailsFilled(false);
                return;
            }

            setShouldShowGetresponseButton(user.getresponse_account !== null);

            if (pathname !== '/') {
                return;
            }

            setShouldShowTaxDetailsBanner(!user.tax_form_filled);
            setShouldShowLinkedAccountBanner(user.getresponse_account === null);
        } catch {
            return null;
        }
    }, [api, pathname, session, setPersonalDetailsFilled]);

    useEffect(() => {
        if (session !== null) {
            checkIfShouldShowBanner();
        }
    }, [checkIfShouldShowBanner, session]);

    useEffect(() => {
        if (session === null) {
            setShouldShowTaxDetailsBanner(false);
            setShouldShowLinkedAccountBanner(false);
            setShouldShowGetresponseButton(false);
            return;
        }

        if (pathname !== '/') {
            setShouldShowTaxDetailsBanner(false);
            setShouldShowLinkedAccountBanner(false);
        }
    }, [pathname, session]);

    function renderSupportNotification() {
        return (
            <Flex color="white" top={0} left={0} width="100%" padding="5px" zIndex={9999} background="error" justifyContent="center">
                <Text variant="body-medium-bold">
                    You are logged into the user account via the support panel
                </Text>
            </Flex>
        );
    }

    function isAllowedNavbarPath() {
        return pathname !== '/auth/recurring_program/callback' &&
          pathname !== '/auth/getresponse/callback' &&
          pathname !== '/login' &&
          pathname !== '/fill-personal-details';
    }

    return (
        <header>
            {session !== null && session.isSupport === 'yes' ? renderSupportNotification() : null}
            {session !== null && isAllowedNavbarPath() ?
                <Navbar hasLinkedAccount={shouldShowGetresponseButton}/> :
                <NotAuthorizedNavbar/>}
            {shouldShowLinkedAccountBanner ? (
                <Flex
                    minHeight="58px"
                    boxShadow="card"
                    background="sun"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    top="63px"
                    style={{ clipPath: 'inset(0px 0 -8px 0)' }}
                    flexDirection={isLessThan920px ? 'column' : 'row'}
                    paddingX="20px"
                    paddingY="12px"
                    data-testid="navbar_linked_account_banner"
                >
                    <Text variant="body-large-semi-bold" align="center" paddingBottom="2px">
                        <FormattedMessage id="Dashboard.LinkedAccountBanner"/>
                    </Text>
                    <Button
                        marginLeft={isLessThan920px ? '0px' : '30px'}
                        marginTop={isLessThan920px ? '6px' : '0px'}
                        size="extra-small"
                        variant="tertiary"
                        style={{ alignSelf: 'center' }}
                    >
                        <Link href="/account/integrations" style={{ all: 'unset' }}>
                            <FormattedMessage id="Dashboard.LinkedAccountBannerText"/>
                        </Link>
                    </Button>
                </Flex>
            ) : null}
            {shouldShowTaxDetailsBanner ? (
                <Flex
                    minHeight="58px"
                    boxShadow="card"
                    background="sky"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    top="63px"
                    style={{ clipPath: 'inset(0px 0 -8px 0)' }}
                    flexDirection={isLessThan920px ? 'column' : 'row'}
                    paddingX="20px"
                    paddingY="12px"
                    data-testid="navbar_tax_details_banner"
                >
                    <Text variant="body-large-semi-bold" align="center" color="white" paddingBottom="2px">
                        <FormattedMessage id="Dashboard.SetUpYourAccount"/>
                    </Text>
                    <Button
                        marginLeft={isLessThan920px ? '0px' : '30px'}
                        marginTop={isLessThan920px ? '6px' : '0px'}
                        size="extra-small"
                        variant="ghost"
                        paddingX="24px"
                        style={{ alignSelf: 'center', borderColor: 'white', color: 'white' }}
                    >
                        <Link href="/account/tax-details" style={{ all: 'unset' }}>
                            <FormattedMessage id="Dashboard.TaxDetailsLink"/>
                        </Link>
                    </Button>
                </Flex>
            ) : null}
        </header>
    );
}
