import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { LocaleContext } from '../context/LocaleContext';
import { availableLanguages, defaultLocale, LANGUAGE_COOKIE } from '../constants/languages';
import { useRouter } from 'next/router';

type LocaleProviderProps = {
    children: JSX.Element;
};

export function LocaleProvider({ children }: LocaleProviderProps): JSX.Element {
    const [locale, setLocale] = useState(defaultLocale);
    const [cookies, setCookie] = useCookies([LANGUAGE_COOKIE]);
    const router = useRouter();
    const routerLocale = router.locale;

    useEffect(() => {
        if (routerLocale === defaultLocale) {
            const storedLanguage = cookies[LANGUAGE_COOKIE];

            if (availableLanguages.includes(storedLanguage)) {
                setLocale(storedLanguage);
            } else {
                setLocale(defaultLocale);
            }
        } else {
            setLocale(routerLocale);
        }
    }, [cookies, routerLocale]);

    const updateLocale = useCallback((lang: string): void => {
        setCookie(LANGUAGE_COOKIE, lang, { path: '/' });
    }, [setCookie]);

    const providerValue = useMemo(() => ({ locale, updateLocale }),
        [locale, updateLocale]);

    return (
        <LocaleContext.Provider value={providerValue}>
            {children}
        </LocaleContext.Provider>
    );
}
