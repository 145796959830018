import { useContext } from 'react';
import { ApiContext } from '../context/ApiContext';

export default function useApi() {
    const context = useContext(ApiContext);

    if (context === undefined) {
        throw new Error('useApi must be used within a ApiProvider');
    }

    return context;
}
