import React from 'react';

interface IDimensions {
    width: number;
    height: number;
}

export function useWindowDimensions(): IDimensions {
    const [windowDimensions, setWindowDimensions] = React.useState({ width: 0, height: 0 });

    React.useEffect(() => {
        function getWindowDimensions(): IDimensions {
            const { innerWidth: width, innerHeight: height } = window;
            return {
                width,
                height,
            };
        }

        function handleResize(): void {
            setWindowDimensions(getWindowDimensions());
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
