import React from 'react';
import { ReactCountryFlag } from 'react-country-flag';
import { Popper } from '@gr/ui';
import { Button, Flex, Text } from '@gr/ui-neo';
import { FormattedMessage } from 'react-intl';
import { AccessibilityOffIcon } from '../../icons/AccessibilityOffIcon';
import { AccessibilityOnIcon } from '../../icons/AccessibilityOnIcon';
import style from './styles/Footer.module.scss';
import { ThemeContext } from '../ThemeContext/ThemeContext';
import { ThemeActionType } from '../ThemeContext/constants/themeActionTypes';
import {
    setHiContrastTheme,
    setNeumorphicTheme,
} from '../ThemeContext/actions/themeContext';
import { useRouter } from 'next/router';
import { availableLanguages, defaultLocale, languages } from '../../constants/languages';
import { ClickAwayListener } from '../../hooks/ClickAwayListener';
import Link from 'next/link';
import { GlobeIcon } from '../../icons/GlobeIcon';
import useLocale from '../../hooks/useLocale';
import useSession from '../../hooks/useSession';
import { EN_TOS_URL, PL_TOS_URL } from '../../constants/urls';

export function Footer(): JSX.Element {
    const router = useRouter();
    const { asPath } = router;
    const [isHighContrast, setIsHighContrast] = React.useState(false);
    const [, dispatch] = React.useContext(ThemeContext);
    const [popperState, setPopperState] = React.useState({
        isOpen: false,
        anchorEl: undefined,
    });
    const currentYear = new Date().getFullYear();
    const { locale, updateLocale } = useLocale();
    const { session } = useSession();

    const handleLocaleOptionClick = React.useCallback(
        async (code) => {
            updateLocale(code);
            router.push(asPath, asPath, { locale: defaultLocale });
            setPopperState((prev) => ({
                ...prev,
                isOpen: !prev.isOpen,
            }));
            await fetch('/api/personal_details/locale', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    locale: code,
                }),
            });
        },
        [asPath, router, updateLocale],
    );

    function handleContrastClick(): void {
        if (isHighContrast) {
            localStorage.setItem('themeName', ThemeActionType.Neumorphic);
            dispatch(setNeumorphicTheme());
        } else {
            localStorage.setItem('themeName', ThemeActionType.Hicontrast);
            dispatch(setHiContrastTheme());
        }
        setIsHighContrast(!isHighContrast);
    }

    function handleClick(event: MouseEvent): void {
        const newAnchorEl = event.currentTarget;

        setPopperState((prev) => ({
            ...prev,
            anchorEl: newAnchorEl,
            isOpen: !prev.isOpen,
        }));
    }

    function handleClickAway(): void {
        return setPopperState((prev) => ({ ...prev, isOpen: false }));
    }

    React.useEffect(() => {
        const themeName = localStorage.getItem('themeName');
        setIsHighContrast(themeName === ThemeActionType.Hicontrast);
        dispatch({ type: themeName });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // TODO: fix dependency array list

    function privacyPolicyUrl(lang: string): string {
        switch (lang) {
            case 'pl': {
                return 'https://www.getresponse.pl/informacje-prawne/polityka-prywatnosci';
            }
            default: {
                return 'https://www.getresponse.com/legal/privacy';
            }
        }
    }

    const termsOfServiceLink = () => {
        if (session) {
            const tosUrl = session.country === 'pl' ? PL_TOS_URL : EN_TOS_URL;

            return (
                <Link href={tosUrl} className={style.link}>
                    <FormattedMessage id="Footer.TermsOfService"/>
                </Link>
            );
        }
    };

    return (
        <footer className={style.container}>
            <div className={style.links}>
                <span>
                    <FormattedMessage
                        id="Footer.Copyright"
                        values={{ year: currentYear }}
                    />
                </span>

                {termsOfServiceLink()}

                <Link href={privacyPolicyUrl(locale)} className={style.link}>
                    <FormattedMessage id="Footer.PrivacyPolicy"/>
                </Link>

                <a
                    href="https://www.getresponse.com/legal/web-accessibility-statement"
                    className={style.link}
                    target="_blank"
                >
                    <FormattedMessage id="Footer.Accessibility"/>
                </a>
            </div>
            <div className={style.actions}>
                <div
                    className={style.contrast}
                    onClick={handleContrastClick}
                    role="button"
                    tabIndex={0}
                >
                    {isHighContrast ? <AccessibilityOnIcon/> : <AccessibilityOffIcon/>}
                    <span className={style.text}>
                        {isHighContrast ? (
                            <FormattedMessage id="Footer.HighContrast.Off"/>
                        ) : (
                            <FormattedMessage id="Footer.HighContrast.On"/>
                        )}
                    </span>
                </div>
                <Flex position="relative" className={style.language}>
                    <ClickAwayListener clickAwayFunction={handleClickAway}>
                        <Button
                            size="small"
                            variant="ghost"
                            className={style.languageButton}
                            onClick={(event) => handleClick(event)}
                        >
                            <GlobeIcon/>
                            <Text
                                variant="body-small"
                                tag="span"
                                color="slathe"
                                textDecoration="underline"
                                marginLeft="8px"
                                className={style.languageButtonText}
                            >
                                <FormattedMessage
                                    id={languages[locale] ? languages[locale] : locale}
                                />
                            </Text>
                        </Button>
                        <Popper
                            isOpen={popperState.isOpen}
                            anchorEl={popperState.anchorEl}
                            dropHorizontal="left"
                            dropVertical="top"
                            className={style.languagePopper}
                        >
                            {availableLanguages.map((languageCode) => (
                                <div
                                    key={languageCode}
                                    onClick={() => handleLocaleOptionClick(languageCode)}
                                    role="button"
                                    tabIndex={0}
                                    className={style.languagePopperText}
                                >
                                    <ReactCountryFlag
                                        countryCode={languageCode === 'en' ? 'gb' : languageCode}
                                    />
                                    <Text variant="body-medium" tag="span" marginLeft="6px">
                                        <FormattedMessage
                                            id={
                                                languages[languageCode]
                                                    ? languages[languageCode]
                                                    : languageCode
                                            }
                                        />
                                    </Text>
                                </div>
                            ))}
                        </Popper>
                    </ClickAwayListener>
                </Flex>
            </div>
        </footer>
    );
}
