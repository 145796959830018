import React from 'react';
import { Box, Button } from '@gr/ui-neo';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import styles from './styles/Navbar.module.scss';
import { Path } from './constants/paths';
import { GetresponseLogoIcon } from '../../icons/GetresponseLogoIcon';
import { useWindowDimensions } from '../../hooks/WindowDimensions';
import { NavbarLinks } from './NavbarLinks';
import { NavbarMobile } from './NavbarMobile';
import { NavbarControls } from './NavbarControls';
import { useRouter } from 'next/router';
import useSession from '../../hooks/useSession';

export function Navbar({ hasLinkedAccount }: { hasLinkedAccount: boolean }): JSX.Element {
    const [isMobile, setIsMobile] = React.useState(false);
    const { width } = useWindowDimensions();
    const router = useRouter();
    const { pathname } = router;
    const { session } = useSession();

    React.useEffect(() => {
        if (width < 962) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [width]);

    const grSamlUrl = () => {
        if (process.env.NEXT_PUBLIC_GR_SAML_URL) {
            return process.env.NEXT_PUBLIC_GR_SAML_URL;
        } else if (window.location.host === 'partners.getresponse.com') {
            return 'https://app.getresponse.com/affiliates-connect/apc';
        } else {
            return 'https://beta-smb-app.getresponse.com/affiliates-connect/apc';
        }
    };

    return (
        <Box tag="nav" className={styles.container} marginBottom={pathname === '/' ? '2px' : '42px'}>
            <Link href={Path.Dashboard}>
                <GetresponseLogoIcon/>
            </Link>
            {width ? (
                <div className={styles.navigation}>
                    {isMobile ? null : <NavbarLinks/>}
                    <div className={styles.navigationControls}>
                        {hasLinkedAccount ? (
                            <Button
                                tag="a"
                                href={grSamlUrl()}
                                target="_blank"
                                variant="secondary"
                                size="small"
                                marginRight="32px"
                                data-testid="navbar_getresponse_link"
                                isDisabled={session?.isSupport === 'yes'}
                            >
                                <FormattedMessage id="Navbar.Links.GetResponse"/>
                            </Button>
                        ) : null}
                        {isMobile ? <NavbarMobile/> : <NavbarControls/>}
                    </div>
                </div>
            ) : null}
        </Box>
    );
}
