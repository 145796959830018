import cookie from 'cookie';
import { type SessionData, SESSION_COOKIE, AUTH_COOKIE } from '../types/session';
import { type NextApiRequest } from 'next';
import { type IncomingMessage } from 'http';
import { sessionInitData } from '../context/SessionContext';

export function getSession(request?: NextApiRequest|IncomingMessage): SessionData {
    let cookies;
    let serverSideCookie;

    try {
        cookies = request ? cookie.parse(request.headers?.cookie) : cookie.parse(document.cookie);
        const clientSideCookie = JSON.parse(cookies[SESSION_COOKIE]);

        if (request) { serverSideCookie = JSON.parse(cookies[AUTH_COOKIE]); }

        const storedSession = { ...clientSideCookie, ...serverSideCookie };

        return storedSession as unknown as SessionData;
    }
    catch {
        return sessionInitData;
    }
}
