import React, { useEffect, useState, type ReactNode, useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { SessionContext, sessionInitData } from '../context/SessionContext';
import { type SessionData, type Session, SESSION_COOKIE } from '../types/session';
import { useRouter } from 'next/router';
import { Path } from '../components/Navbar/constants/paths';

export function SessionProvider(
    { children, sessionInit = sessionInitData }:
    {children: ReactNode; sessionInit?: SessionData}): JSX.Element {
    const [session, setSession] = useState(sessionInit);
    const [cookies, setCookie, removeCookie] = useCookies([SESSION_COOKIE]);
    const router = useRouter();

    const loadSession = useCallback((): void => {
        const storedSession = cookies[SESSION_COOKIE];
        if (storedSession !== sessionInitData && storedSession !== undefined) {
            setSession({
                isSupport: storedSession?.isSupport,
                isAgency: storedSession?.isAgency,
                assignedToRecurring: storedSession?.assignedToRecurring,
                assignedToBounty: storedSession?.assignedToBounty,
                personalDetailsFilled: storedSession?.personalDetailsFilled,
                tosAcceptanceRequired: storedSession?.tosAcceptanceRequired,
                country: storedSession?.country,
            });
        }
    }, [cookies]);

    useEffect(() => {
        loadSession();
    }, [loadSession]);

    const store = useCallback((values: SessionData): void => {
        if (values === sessionInitData) {
            removeCookie(SESSION_COOKIE, { path: '/' });
            setSession(null);
            return;
        }

        const sessionData = { ...values };
        setCookie(SESSION_COOKIE, JSON.stringify(sessionData), { path: '/' });
        setSession(sessionData);
    }, [setCookie, removeCookie]);

    const signIn = useCallback((values: SessionData, redirect: boolean = false): void => {
        store(values);

        if (redirect) {
            router.push('/');
        }
    }, [router, store]);

    const signOut = useCallback(async (): Promise<void> => {
        await fetch('/api/logout', { method: 'DELETE' });
        store(sessionInitData);
        window.location.replace('/login');
    }, [store]);

    // allows to update stored session
    const update = useCallback((values: SessionData): void => {
        const storedSession = cookies[SESSION_COOKIE];
        store({ ...storedSession, ...values });
    }, [cookies, store]);

    const setPersonalDetailsFilled = useCallback((filled: boolean): void => {
        const storedSession = cookies[SESSION_COOKIE];
        storedSession.personalDetailsFilled = filled === true ? 'yes' : 'no';
        store(storedSession);

        const redirectPath = filled === true ? '/' : Path.FillPersonalDetails;
        router.push(redirectPath);
    }, [cookies, store, router]);

    const providerValue = useMemo((): Session => (
        { session, signIn, signOut, update, setPersonalDetailsFilled }
    ),
    [session, signIn, signOut, update, setPersonalDetailsFilled]);

    return (
        <SessionContext.Provider value={providerValue}>
            {children}
        </SessionContext.Provider>
    );
}
