import React, { useCallback, useMemo } from 'react';
import { ApiContext } from '../context/ApiContext';
import useSession from '../hooks/useSession';

type ApiProviderProps = {
    children: JSX.Element;
};

export function ApiProvider({ children }: ApiProviderProps): JSX.Element {
    const { signOut } = useSession();

    // Wrapper function around fetch() with handling of 401 Unauthorized response
    const api = useCallback(async (url: RequestInfo | URL, config: RequestInit = {}): Promise<Response> => {
        const response = await fetch(url, config);

        if (response.status === 401) {
            await signOut();
        }

        return response;
    }, [signOut]);

    const providerValue = useMemo(() => ({ api }), [api]);

    return (
        <ApiContext.Provider value={providerValue}>
            {children}
        </ApiContext.Provider>
    );
}
