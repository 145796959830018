import React from 'react';
import { ThemeProvider as GRUIThemeProvider } from '@gr/ui';
import { defaultTheme, highContrastTheme, ThemeProvider as NeoThemeProvider } from '@gr/ui-neo';
import { ThemeContext } from '../ThemeContext/ThemeContext';

export function ThemeProviderWrapper({ children }: { children: React.ReactNode }): JSX.Element {
    const [state] = React.useContext(ThemeContext);

    return (
        <NeoThemeProvider theme={state.name === 'neumorphic' ? defaultTheme : highContrastTheme}>
            <GRUIThemeProvider theme={state}>{children}</GRUIThemeProvider>
        </NeoThemeProvider>
    );
}
