import * as React from 'react';

export function GlobeIcon(): JSX.Element {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 0C5.333 0 0 5.333 0 12s5.333 12 12 12 12-5.333 12-12S18.667 0 12 0zm6.133 10.933c0 .267 0 .534.134.8.533.267 1.2-.133 1.466.8-.8.534-1.466.934-2.266 1.467-.534-.933-.934-1.867-1.467-2.667-.133 0-.133.134-.267.134.534 1.066.934 2.133 1.334 3.2.533-.134.933-.267 1.2-.267.133 1.467-1.2 1.867-1.6 2.667V18.4c-.8.667-1.2 1.2-1.734 1.867-.666.666-1.2 1.2-2.266.933-.4-.8-1.2-1.467-.934-2.533.267-1.2-.4-2.134-.8-3.334H7.867c-.667-.4-1.067-.933-1.334-1.2-.533-2.4.4-3.866 1.867-5.066-.667-.534-.667-.534-.533-1.334.4-.133.8-.133 1.066-.266C8.8 7.2 8.8 6.8 8.667 6.533c.666-.533 1.6-1.066 2.133-1.466.667 0 1.2.133 1.6.133L13.6 4c-.4-.4-.8-.533-1.067-.8-.133.4-.266.933-.4 1.467-.8-.4-1.333-.534-1.866-.934.666-.933 1.6-1.333 2.666-1.2h2.4c3.867 1.6 6.534 5.467 6.534 10v.934c-.4-1.867-2-2.267-3.734-2.534zM13.2 8.8c-.533-.667-.533-1.467-1.467-1.333.267.266.4.666.667.933-.133 0-.133.133-.267.133-.4-.4-.933-.8-1.333-1.2-.933.267-1.6.8-1.867 1.867.8-.133 1.334-.133 1.867-.267.667.534 1.2 1.067 1.6 1.6.4-.266.667-.4.8-.533 1.867.4 1.867.4 2.4-.533-.667-.4-1.2-.8-1.867-1.067-.133.133-.266.267-.533.4z"
                fill="#4D5C70"
            />
        </svg>
    );
}
