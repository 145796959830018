import { themes } from '@gr/ui';
import { ThemeActionType } from '../constants/themeActionTypes';

export const themeReducer = (state, action): typeof themes.neumorphic | typeof themes['neumorphic-hicontrast'] => {
    switch (action.type) {
        case ThemeActionType.Hicontrast:
            return themes['neumorphic-hicontrast'];
        case ThemeActionType.Neumorphic:
            return themes.neumorphic;
    }
    return themes.neumorphic;
};
