import React from 'react';
import { type Session, type SessionData } from '../types/session';

export const sessionInitData: SessionData = null;

export const SessionContext = React.createContext<Session>(
    { session: sessionInitData,
        signIn: null,
        signOut: null,
        update: null,
        setPersonalDetailsFilled: null },
);
