import { ThemeActionType } from '../constants/themeActionTypes';

type Action<T> = {
    type: T;
};

export function setNeumorphicTheme(): Action<ThemeActionType.Neumorphic> {
    return {
        type: ThemeActionType.Neumorphic,
    };
}

export function setHiContrastTheme(): Action<ThemeActionType.Hicontrast> {
    return {
        type: ThemeActionType.Hicontrast,
    };
}
