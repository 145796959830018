import * as React from 'react';

export function HamburgerIcon(): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="4" width="18" height="3" rx="1.5" fill="#7990A1"/>
            <rect x="3" y="10" width="18" height="3" rx="1.5" fill="#7990A1"/>
            <rect x="3" y="16" width="18" height="3" rx="1.5" fill="#7990A1"/>
        </svg>
    );
}
