import React from 'react';

type ClickAwayType = () => unknown | undefined;

interface AwayListenerProps {
    children: React.ReactNode;
    clickAwayFunction: ClickAwayType;
}

// eslint-disable-next-line @typescript-eslint/typedef
function useAwayListener(ref, clickAway: ClickAwayType): void {
    React.useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (ref.current && !ref.current.contains(event.target) && clickAway !== undefined) {
                clickAway();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return (): void => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

export function ClickAwayListener(props: AwayListenerProps): React.ReactElement {
    const { children, clickAwayFunction } = props;

    const wrapperRef = React.useRef(null);
    useAwayListener(wrapperRef, clickAwayFunction);

    return <div ref={wrapperRef}>{children}</div>;
}
