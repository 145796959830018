import React from 'react';
import { Layout } from '../types/layouts';
import { NotAuthorized } from './NotAuthorized';
import { EmptyLayout } from './empty';
import { DefaultLayout } from './default';

type LayoutWrapperProps = {
    layout?: Layout;
};

LayoutWrapper.defaultProps = {
    layout: Layout.DEFAULT,
};

export function LayoutWrapper({ layout, children }: React.PropsWithChildren<LayoutWrapperProps>): JSX.Element {
    let LayoutComponent;
    switch (layout) {
        case Layout.EMPTY:
            LayoutComponent = EmptyLayout;
            break;
        case Layout.NOTAUTHORIZED:
            LayoutComponent = NotAuthorized;
            break;
        default:
            LayoutComponent = DefaultLayout;
    }
    return <LayoutComponent>{children}</LayoutComponent>;
}
