export type SessionData = {
    id?: number;
    email?: string;
    isSupport?: 'yes'|'no';
    accessToken?: string;
    isAgency?: 'yes'|'no';
    assignedToBounty?: 'yes'|'no';
    assignedToRecurring?: 'yes'|'no';
    personalDetailsFilled?: 'yes'|'no';
    tosAcceptanceRequired?: 'yes'|'no';
    country?: string;
};

export type Session = {
    session: SessionData|null;
    signIn: (values: SessionData, redirect?: boolean) => void;
    signOut: () => void;
    update: (values: SessionData) => void;
    setPersonalDetailsFilled: (filled: boolean) => void;
};

export const SESSION_COOKIE = '_u';
export const AUTH_COOKIE = '_auth';
