import React, { createContext, useMemo, useReducer } from 'react';
import { themes } from '@gr/ui';
import { themeReducer } from './reducers/themeContext';

export const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }): JSX.Element => {
    const [state, dispatch] = useReducer(themeReducer, themes.neumorphic);
    const theme = useMemo(() => [state, dispatch], [state, dispatch]);

    return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
