import * as React from 'react';

export function AccessibilityOnIcon(): JSX.Element {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.8 0 12 0z" fill="#202730"/>
            <path
                d="M12.093 7.81c1.031 0 1.867-.853 1.867-1.905S13.124 4 12.093 4c-1.03 0-1.866.853-1.866 1.905s.835 1.904 1.866 1.904z"
                fill="#fff"
            />
            <path
                d="M14.147 9.917c1.554-.126 3.034-.398 4.23-.829a.957.957 0 00.57-1.215.929.929 0 00-1.191-.58c-1.45.522-3.528.802-5.686.802-2.155 0-4.287-.28-5.841-.808a.93.93 0 00-1.18.602.957.957 0 00.59 1.205c1.22.415 2.688.683 4.214.813v3.142c-.06.382-.1.692-.135.96-.08.621-.131 1.022-.344 1.566l-.025.064-.764 2.923c-.16.61.196 1.236.793 1.4.598.162 1.211-.2 1.37-.81l.731-2.795a7.64 7.64 0 00.385-1.5h.29c.077.408.184.83.367 1.31l.73 2.794c.16.61.773.972 1.37.81.598-.163.954-.79.794-1.4l-.764-2.922-.024-.064c-.228-.58-.276-.924-.365-1.555-.03-.219-.066-.472-.115-.781V9.917z"
                fill="#fff"
            />
        </svg>
    );
}
