import * as React from 'react';

export function GetresponseLogoIcon(): JSX.Element {
    return (
        <svg width="51" height="40" viewBox="0 0 51 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd)">
                <path
                    d="M41 28.7365C41 29.4528 40.4178 29.9986 39.7329 29.9986H12.2671C11.5822 30.0327 11 29.4528 11 28.7365V12.262C11 11.5798 11.5822 11 12.2671 11H39.6986C40.4178 11 40.9658 11.5798 40.9658 12.262V28.7365H41Z"
                    fill="#00AEEF"
                />
                <path
                    d="M40.726 11.4775C37.1644 16.969 31.6507 22.0171 25.9657 21.8807C23.8767 21.8466 21.4794 21.2326 19.4589 19.5954C17.952 18.3675 16.4452 16.4574 15.863 13.7287C15.6233 13.7287 15.452 13.7287 15.2465 13.7287C14.3219 13.7287 13.7739 14.5473 13.8767 15.2977C13.9109 15.4683 13.9452 15.6729 13.9794 15.8776C14.4246 18.538 16 21.5396 18.637 23.4838C20.6918 24.9846 23.226 25.9738 26 25.9738C31 25.9738 36.3424 22.8016 41 14.6497V12.262C41 11.9892 40.8972 11.6822 40.726 11.4775Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter id="filter0_dd" x="0" y="0" width="51" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow"/>
                    <feOffset dx="3" dy="3"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.47451 0 0 0 0 0.564706 0 0 0 0 0.631373 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect2_dropShadow"/>
                    <feOffset dx="-3" dy="-3"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
}
