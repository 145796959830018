import * as React from 'react';

export function OffIcon(): JSX.Element {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.7 6.50999C6.28719 7.40135 5.19974 8.72481 4.59928 10.2837C3.99883 11.8425 3.91744 13.5535 4.3672 15.1623C4.81697 16.7711 5.77388 18.1918 7.0957 19.2133C8.41751 20.2347 10.0337 20.8023 11.7039 20.8318C13.3741 20.8612 15.0093 20.3509 16.3663 19.3767C17.7232 18.4024 18.7296 17.0163 19.2358 15.4244C19.742 13.8324 19.721 12.1196 19.1758 10.5406C18.6307 8.96155 17.5905 7.60058 16.21 6.65999"
                stroke="#7990A1"
                strokeWidth="2.2"
                strokeLinecap="round"
            />
            <path d="M11.84 3.10001V11.38" stroke="#7990A1" strokeWidth="2.2" strokeLinecap="round"/>
        </svg>
    );
}
