export const CUSTOMER_SUPPORT_URL = 'https://www.getresponse.com/features/support';
const { APC_API_URL } = process.env;

// Account
const SETTINGS_URL = `${APC_API_URL}/api/settings`;

export const CHANGE_PASSWORD_URL = `${SETTINGS_URL}/passwords`;
export const DISCONNECT_ACCOUNT_URL = `${SETTINGS_URL}/linked_accounts/disconnect`;
export const PAYOUT_DETAILS_URL = `${SETTINGS_URL}/payout_details`;
export const CANCEL_PAYOUT_DETAILS_URL = `${SETTINGS_URL}/payout_details/cancel`;
export const CONFIRM_PAYOUT_DETAILS_URL = `${SETTINGS_URL}/payout_details/confirm`;
export const PERSONAL_DETAILS_URL = `${SETTINGS_URL}/personal_details`;
export const PERSONAL_DETAILS_LOCALE_URL = `${SETTINGS_URL}/personal_details/locale`;

export const TAX_DETAILS_URL = `${SETTINGS_URL}/tax_details`;
export const TAX_DETAILS_CAMP_URL = `${TAX_DETAILS_URL}/camp`;
export const TAX_DETAILS_DOWNLOAD_FORM_URL = `${TAX_DETAILS_URL}/download_form`;
export const TAX_DETAILS_UPLOAD_FORM_URL = `${TAX_DETAILS_URL}/upload_form`;

// Auth
export const AUTH_GETRESPONSE_URL = `${APC_API_URL}/api/users/auth/getresponse`;
export const AUTH_GETRESPONSE_CALLBACK_URL = `${AUTH_GETRESPONSE_URL}/callback`;
export const AUTH_RECURRING_URL = `${APC_API_URL}/api/users/auth/recurring_program`;
export const AUTH_RECURRING_CALLBACK_URL = `${AUTH_RECURRING_URL}/callback`;
export const AUTH_SAML_URL = `${APC_API_URL}/api/saml/auth`;
export const LOGOUT_URL = `${APC_API_URL}/users/sign_out`;
export const AUTH_GOOGLE_URL = `${APC_API_URL}/api/users/auth/google`;
export const AUTH_GOOGLE_CALLBACK_URL = `${AUTH_GOOGLE_URL}/callback`;
export const AUTH_APPLE_URL = `${APC_API_URL}/api/users/auth/apple`;
export const AUTH_APPLE_CALLBACK_URL = `${AUTH_APPLE_URL}/callback`;

// Dashboard
const DASHBOARD_URL = `${APC_API_URL}/api/dashboard`;

export const EARNINGS_CHART_URL = `${DASHBOARD_URL}/earnings/chart`;
export const EARNINGS_TABLE_URL = `${DASHBOARD_URL}/earnings/table`;

export const LINK_GENERATORS_URL = `${DASHBOARD_URL}/link_generators`;

export const LINKS_AND_CAMPAIGNS_CHART_URL = `${DASHBOARD_URL}/links_and_campaigns/chart`;
export const LINKS_AND_CAMPAIGNS_TABLE_URL = `${DASHBOARD_URL}/links_and_campaigns/table`;

export const ACCOUNTS_AND_CLICKS_URL = `${DASHBOARD_URL}/performance_results/accounts_and_clicks`;
export const EARNINGS_URL = `${DASHBOARD_URL}/performance_results/earnings`;
export const PENDING_EARNINGS_LIST = `${DASHBOARD_URL}/performance_results/pending_earnings_list`;

export const PROMOTIONAL_MATERIALS_CHART_URL = `${DASHBOARD_URL}/promotional_materials/chart`;
export const PROMOTIONAL_MATERIALS_TABLE_URL = `${DASHBOARD_URL}/promotional_materials/table`;

export const REFERRED_ACCOUNTS_CHART_URL = `${DASHBOARD_URL}/referred_accounts/chart`;
export const REFERRED_ACCOUNTS_DETAILS_URL = `${DASHBOARD_URL}/referred_accounts/details`;
export const REFERRED_ACCOUNTS_TABLE_URL = `${DASHBOARD_URL}/referred_accounts/table`;

export const CURRENT_TIER_URL = `${DASHBOARD_URL}/user_rate_tiers/current_tier`;

// My inquiries
export const TICKETS_URL = `${APC_API_URL}/api/help/tickets`;

// Passwords
export const USER_PASSWORD_URL = `${APC_API_URL}/api/users/password`;

// Programs
export const PROGRAMS_URL = `${APC_API_URL}/api/programs`;

// Promotional materials
export const PROMOTIONAL_MATERIALS_URL = `${APC_API_URL}/api/tools/promotional_materials`;

// Users
export const USERS_URL = `${APC_API_URL}/api/users`;
export const USER_LOCALE_URL = `${APC_API_URL}/api/users/locale`;

// Creds
export const CREDENTIALS_URL = `${APC_API_URL}/credentials/me`;

// Confirmation
export const CONFIRMATION_URL = `${APC_API_URL}/api/users/confirmation`;

// Library
export const LIBRARY_URL = `${APC_API_URL}/api/help/library`;

// Blog posts
export const BLOG_POSTS_URL = `${LIBRARY_URL}/blog`;

// TOS
export const PL_TOS_URL = 'https://www.getresponse.pl/informacje-prawne/program-partnerski';
export const EN_TOS_URL = 'https://www.getresponse.com/legal/affiliate-program';
