import React from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { PageMain } from './styled/PageMain';

export function NotAuthorized({ children }: React.PropsWithChildren<Record<string, never>>): JSX.Element {
    return (
        <React.Fragment>
            <Header/>
            <PageMain>{children}</PageMain>
            <Footer/>
        </React.Fragment>
    );
}
