import React from 'react';
import { FormattedMessage } from 'react-intl';
import style from './styles/NotAuthorizedNavbar.module.scss';
import { GetresponseLogoIcon } from '../../icons/GetresponseLogoIcon';

export function NotAuthorizedNavbar(): JSX.Element {
    return (
        <nav className={style.container}>
            <a href="https://www.getresponse.com" target="_blank">
                <GetresponseLogoIcon/>
            </a>
            <div className={style.textWrapper}>
                <span className={style.color}>
                    <FormattedMessage id="Navbar.PartnerText"/>
                </span>{' '}
                <FormattedMessage id="Navbar.ProgramText"/>
            </div>
        </nav>
    );
}
