import * as React from 'react';

export function NavbarAccountIcon(): JSX.Element {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <path
                    d="M24.708 24.2821C22.7965 21.7335 19.6107 20.0344 16.0001 20.0344C12.3895 20.0344 9.20362 21.7335 7.29212 24.2821M16 10.3713C18.6549 10.3713 20.7788 12.4951 20.7788 15.1499C20.7788 17.8047 18.6549 19.9286 16 19.9286C13.3452 19.9286 11.2213 17.8047 11.2213 15.1499C11.2213 12.4951 13.3452 10.3713 16 10.3713ZM28 15.9997C28 22.627 22.6274 27.9995 16 27.9995C9.37258 27.9995 4 22.627 4 15.9997C4 9.37247 9.37258 4 16 4C22.6274 4 28 9.37247 28 15.9997Z"
                    stroke="#7990A1"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <filter id="filter0_d" x="0" y="0" width="32" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
}
