import React from 'react';
import styles from './styles/Navbar.module.scss';
import { NavbarHelperIcon } from '../../icons/NavbarHelperIcon';
import { ClickAwayListener } from '../../hooks/ClickAwayListener';
import { Popper } from '@gr/ui';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { NavbarAccountIcon } from '../../icons/NavbarAccountIcon';
import { OffIcon } from '../../icons/OffIcon';
import { Path } from './constants/paths';
import useSession from '../../hooks/useSession';

export function NavbarControls(): JSX.Element {
    const [isNavbarHelperOpen, setIsNavbarHelperOpen] = React.useState<boolean>(null);
    const navbarHelperContainer = React.useRef(null);
    const [isNavbarAccountOpen, setIsNavbarAccountOpen] = React.useState<boolean>(null);
    const navbarAccountContainer = React.useRef(null);
    const { signOut } = useSession();

    const handleHelperClick = React.useCallback((): void => {
        setIsNavbarHelperOpen((prev) => !prev);
    }, []);

    const handleHelperClickAway = React.useCallback((): void => {
        setIsNavbarHelperOpen(false);
    }, []);

    const handleAccountClick = React.useCallback((): void => {
        setIsNavbarAccountOpen((prev) => !prev);
    }, []);

    const handleAccountClickAway = React.useCallback((): void => {
        setIsNavbarAccountOpen(false);
    }, []);

    const helperOpenClass = isNavbarHelperOpen ? styles.open : '';
    const accountOpenClass = isNavbarAccountOpen ? styles.open : '';
    return (
        <React.Fragment>
            <div
                onClick={handleHelperClick}
                role="button"
                tabIndex={0}
                className={`${styles.navigationControlsHelper} ${helperOpenClass}`}
                ref={navbarHelperContainer}
            >
                <NavbarHelperIcon/>
            </div>
            <ClickAwayListener clickAwayFunction={handleHelperClickAway}>
                <Popper
                    dropVertical="bottom"
                    dropHorizontal="left"
                    isOpen={isNavbarHelperOpen}
                    anchorEl={navbarHelperContainer.current}
                    className={`${styles.navigationControlsPopper} ${styles.navigationControlsPopperArrowHelper}`}
                >
                    <div className={styles.navigationControlsPopperHelperLink}>
                        <Link href={Path.HelpCenter}>
                            <FormattedMessage id="Navbar.Helper.HelpCenter"/>
                        </Link>
                    </div>

                    <div className={styles.navigationControlsPopperHelperLink}>
                        <Link href={Path.MyInquiries}>
                            <FormattedMessage id="Navbar.Helper.MyInquiries"/>
                        </Link>
                    </div>

                    <div className={styles.navigationControlsPopperHelperLink}>
                        <Link href={Path.Library}>
                            <FormattedMessage id="Navbar.Helper.Library"/>
                        </Link>
                    </div>
                </Popper>
            </ClickAwayListener>
            <div
                onClick={handleAccountClick}
                role="button"
                tabIndex={0}
                ref={navbarAccountContainer}
                className={`${styles.navigationControlsAccount} ${accountOpenClass}`}
            >
                <NavbarAccountIcon/>
            </div>
            <ClickAwayListener clickAwayFunction={handleAccountClickAway}>
                <Popper
                    dropVertical="bottom"
                    dropHorizontal="left"
                    isOpen={isNavbarAccountOpen}
                    anchorEl={navbarAccountContainer.current}
                    className={`${styles.navigationControlsPopper} ${styles.navigationControlsPopperArrowAccount}`}
                >
                    <div role="button" tabIndex={0} className={styles.navigationControlsPopperAccountLink} onClick={handleAccountClickAway}>
                        <Link href={Path.MyAccount}>
                            <FormattedMessage id="Navbar.Account.MyAccount"/>
                        </Link>
                    </div>
                    <div className={styles.navigationControlsPopperDivider}/>
                    <div className={styles.navigationControlsPopperSignOutLink} onClick={signOut}>
                        <button type="button">
                            <FormattedMessage id="Navbar.Account.SignOut"/>
                        </button>
                        <OffIcon/>
                    </div>
                </Popper>
            </ClickAwayListener>
        </React.Fragment>
    );
}
